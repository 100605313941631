import React from 'react';
import ComponentWrapper from '../../../components/layout/ComponentWrapper';
import { StudyDesignOverview } from '../index';

const NSCLC_2L_MONO = () => (
    
    <ComponentWrapper>
        <StudyDesignOverview 
            title="Study Overview<sup>1</sup>" 
            description="An open-label, multicenter, randomized, phase 2/3 trial of single-agent KEYTRUDA vs docetaxel in 1,033 patients with squamous or nonsquamous mNSCLC. 
            The trial included patients whose tumors were PD&#8288;-&#8288;L1 positive based on a TPS ≥1%, as determined by the PD&#8288;-&#8288;L1 immunohistochemistry 22C3 pharmDx assay. Patients also 
            had disease progression following platinum-containing chemotherapy and, if appropriate, targeted therapy for EGFR or ALK genomic tumor aberrations. Patients with an 
            autoimmune disease; a medical condition that required immunosuppression; or who had received more than 30 Gy of thoracic radiation within 26 weeks were ineligible. 
            Patients received KEYTRUDA 2&nbsp;mg/kg intravenously (n=344) or 10&nbsp;mg/kg intravenously (n=346) or docetaxel 75&nbsp;mg/m<sup>2</sup> intravenously (n=343) Q3W until unacceptable toxicity or disease 
            progression or for up to 24 months. Tumor status was assessed every 9 weeks. Co-primary end points were OS and PFS, as assessed by blinded independent central review 
            (BICR) using Response Evaluation Criteria In Solid Tumors v1.1 (RECIST v1.1) modified to follow a maximum of 10 target lesions and a maximum of 5 target lesions per organ. 
            Additional efficacy outcome measures were ORR and DOR." 
        />
    </ComponentWrapper>
);

export default NSCLC_2L_MONO;